/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailabilityPreviewResponse } from '../models/AvailabilityPreviewResponse';
import type { CampaignCreate } from '../models/CampaignCreate';
import type { CampaignDetails } from '../models/CampaignDetails';
import type { CampaignPendingCollaborations } from '../models/CampaignPendingCollaborations';
import type { CampaignUpdate } from '../models/CampaignUpdate';
import type { CAPRequest } from '../models/CAPRequest';
import type { Slot } from '../models/Slot';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CampaignService {
    /**
     * Available slots for specified offer by date
     * Response format is date as a key and list of slots as a value.
     * @param offerId
     * @param startTime
     * @param endTime
     * @param rescheduleId
     * @returns Slot Successful Response
     * @throws ApiError
     */
    public static slots(
        offerId: number,
        startTime: string,
        endTime: string,
        rescheduleId?: (number | null),
    ): CancelablePromise<Record<string, Array<Slot>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/slots',
            query: {
                'offer_id': offerId,
                'startTime': startTime,
                'endTime': endTime,
                'reschedule_id': rescheduleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Preview Availability
     * @param campaignId
     * @param locationId
     * @returns Slot Successful Response
     * @throws ApiError
     */
    public static previewAvailability(
        campaignId: number,
        locationId: number,
    ): CancelablePromise<Record<string, Array<Slot>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/preview-availability',
            query: {
                'campaign_id': campaignId,
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Pending Collabs Count
     * @param brandId
     * @returns CampaignPendingCollaborations Successful Response
     * @throws ApiError
     */
    public static getPendingCollabsCount(
        brandId: number,
    ): CancelablePromise<Array<CampaignPendingCollaborations>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/get_pending_collabs_count/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add
     * @param requestBody
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static add(
        requestBody: CampaignCreate,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Modify
     * @param campaignId
     * @param requestBody
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static modify(
        campaignId: number,
        requestBody: CampaignUpdate,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/update/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stop
     * @param campaignId
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static stop(
        campaignId: number,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/{campaign_id}/stop',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Archive
     * @param campaignId
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static archive(
        campaignId: number,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/{campaign_id}/acrhive',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Activate
     * @param campaignId
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static activate(
        campaignId: number,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/{campaign_id}/activate',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Preview Availability Config
     * @param requestBody
     * @returns AvailabilityPreviewResponse Successful Response
     * @throws ApiError
     */
    public static previewAvailabilityConfig(
        requestBody: CAPRequest,
    ): CancelablePromise<AvailabilityPreviewResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/preview-availability-config',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param campaignId
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static get(
        campaignId: number,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
